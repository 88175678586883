
import { defineComponent, PropType } from "vue";
import { fr as dateFr } from "date-fns/locale";
import { format as dateFormat } from "date-fns";
import ResponsiveStageLayout from "@/components/mobile/ResponsiveStageLayout.vue";
import AppointmentStore from "@/stores/appointment.store";
import PharmacyStore from "@/stores/pharmacy.store";
import FontAwesomeIcon from "@/utils/fontawesome";
import { DateSlot, DaySlots } from "@/services/appointment.service";
import TimeSelection from "@/components/mobile/elements/TimeSelection.vue";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        ResponsiveStageLayout,
        TimeSelection
    },
    props: {
        dateSlots: {
            type: Object as PropType<Record<string, DaySlots>>,
            required: true
        },
        selectedAppointment: {
            type: String,
            required: true
        },
        selectedDate: {
            type: String,
            required: true
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["submit", "back"],
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            appointmentState: AppointmentStore.getState()
        };
    },
    computed: {
        displayDate (): string {
            const date = new Date(this.selectedDate);
            return dateFormat(date, "EEEE d MMMM", { locale: dateFr });
        },
        daySlots (): DaySlots {
            return this.dateSlots[this.selectedDate];
        }
    }
});
