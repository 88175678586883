<template>
    <div class="time">
        {{ formatTime }}
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";

export default defineComponent({
    props: {
        time: {
            type: String,
            required: true
        }
    },
    computed: {
        formatTime (): string {
            return dateFormat(new Date(this.time), "H:mm", { locale: dateFr });
        }
    }
});
</script>

<style scoped lang="scss">
.time {
    cursor: pointer;
    box-shadow: var(--color-shadow-soft) 5px 5px 10px,
    var(--color-light-hard) -5px -5px 10px;
    border-radius: 8px;
    font-size: 5vmin;
    background: var(--color-primary);
    color: var(--color-text-lightest);
    padding: 2vmin;
}
</style>
