<template>
    <ResponsiveStageLayout :stage="stage">
        <div
            class="back"
            @click="$emit('back')"
        >
            <FontAwesomeIcon icon="angle-double-left" />
        </div>
        <h1>{{ pharmacyState.pharmacy.name }}</h1>
        <p>
            Choisissez une date pour votre rdv<br>
            <span class="appointment-name">{{ appointmentState.appointmentTypes[selectedAppointment].name }}</span>
        </p>
        <div class="spacer" />
        <div class="date-wrapper">
            <DateSelection
                v-for="(dateSlot, date) of dateSlots"
                :key="date"
                :date-slot="{date, slots: dateSlot}"
                @click="$emit('submit', date)"
            />
        </div>
        <div class="spacer" />
    </ResponsiveStageLayout>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import { DaySlots } from "@/services/appointment.service";
import ResponsiveStageLayout from "@/components/mobile/ResponsiveStageLayout.vue";
import AppointmentStore from "@/stores/appointment.store";
import PharmacyStore from "@/stores/pharmacy.store";
import DateSelection from "@/components/mobile/elements/DateSelection.vue";

export default defineComponent({
    components: {
        DateSelection,
        FontAwesomeIcon,
        ResponsiveStageLayout
    },
    props: {
        dateSlots: {
            type: Object as PropType<Record<string, DaySlots>>,
            required: true
        },
        selectedAppointment: {
            type: String,
            required: true
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["submit", "back"],
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            appointmentState: AppointmentStore.getState()
        };
    },
    computed: {
    }
});
</script>

<style scoped lang="scss">
.back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2vmin 4vmin;
    font-size: 8vmin;
    cursor: pointer;
    opacity: 0.2;
}

h1,
p {
    padding: 0;
    margin: 0;
    text-align: center;
}

h1 {
    font-size: 6vmin;
}

.appointment-name {
    font-size: 5vmin;
    font-weight: 600;
    color: var(--color-primary);
}

.spacer {
    height: 4vmin;
}

.date-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20vmin, 1fr));
    gap: 4vmin;
}
</style>
