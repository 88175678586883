
import { defineComponent } from "vue";
import _ from "lodash";
import MobileLayout from "@/layouts/MobileLayout.vue";
import PharmacyStore from "@/stores/pharmacy.store";
import AppointmentStore from "@/stores/appointment.store";
import { Pharmacy } from "@/services/pharmacy.service";
import { AppointmentType } from "@/services/appointment.service";
import AppointmentTypeSelection from "@/components/mobile/stages/AppointmentTypeSelection.vue";
import AppointmentDateSelection from "@/components/mobile/stages/AppointmentDateSelection.vue";
import AppointmentTimeSelection from "@/components/mobile/stages/AppointmentTimeSelection.vue";
import AppointmentForm from "@/components/mobile/stages/AppointmentForm.vue";
import AppointmentCheck from "@/components/mobile/stages/AppointmentCheck.vue";

export default defineComponent({
    components: {
        MobileLayout,
        AppointmentCheck,
        AppointmentDateSelection,
        AppointmentForm,
        AppointmentTimeSelection,
        AppointmentTypeSelection
    },
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            pharmacy: {} as Pharmacy,
            appointmentState: AppointmentStore.getState(),
            appointmentTypes: [] as Array<AppointmentType>,
            stage: 0,
            selectedAppointment: "",
            selectedDate: "",
            selectedTime: -1,
            transitionName: "card-right"
        };
    },
    watch: {
        "pharmacyState.pharmacy": {
            immediate: true,
            handler () {
                if (this.pharmacyState.pharmacy) {
                    this.pharmacy = this.pharmacyState.pharmacy;
                }
            }
        },
        "appointmentState.appointmentTypes": {
            immediate: true,
            handler () {
                if (this.appointmentState.appointmentTypes) {
                    this.appointmentTypes = _.values(this.appointmentState.appointmentTypes);
                }
            }
        }
    },
    async mounted () {
        const url = this.$route.params.url as string;
        await PharmacyStore.loadByUrl(url);
        await AppointmentStore.loadByPharmacyId(this.pharmacyState.pharmacy?.id as string);
        this.stage = 1;
    },
    methods: {
        async submitType (appointmentId: string) {
            this.selectedAppointment = appointmentId;
            this.nextStage();
            await AppointmentStore.loadDateSlots(appointmentId);
        },
        submitDate (date: string) {
            this.selectedDate = date;
            this.nextStage();
        },
        submitTime (time: number) {
            this.selectedTime = time;
            this.nextStage();
        },
        nextStage () {
            this.transitionName = "card-right";
            this.stage++;
        },
        previousStage () {
            this.transitionName = "card-left";
            this.stage--;
        }
    }
});
