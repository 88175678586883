<template>
    <div
        class="button"
        @click="$emit('click')"
    >
        <slot />
    </div>
</template>

<script>
export default {
    emits: ["click"]
};
</script>

<style scoped lang="scss">
.button {
    border: 1px solid var(--color-primary);
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3vmin 8vmin;
    color: var(--color-text-light);
    cursor: pointer;
    border-radius: 8vmin;
    box-shadow:
        var(--color-shadow-soft) 5px 5px 10px,
        var(--color-light-hard) -5px -5px 10px;
}
</style>
