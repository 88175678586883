<template>
    <div class="rdv">
        <ResponsiveTopLogo />
        <Appointment />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ResponsiveTopLogo from "@/components/mobile/elements/ResponsiveTopLogo.vue";
import Appointment from "@/components/mobile/Appointment.vue";

export default defineComponent({
    components: {
        ResponsiveTopLogo,
        Appointment
    }
});
</script>

<style scoped lang="scss">
.rdv {
    font-size: 4vmin;
}
</style>
