
import { defineComponent, PropType } from "vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import { DaySlots } from "@/services/appointment.service";
import ResponsiveStageLayout from "@/components/mobile/ResponsiveStageLayout.vue";
import AppointmentStore from "@/stores/appointment.store";
import PharmacyStore from "@/stores/pharmacy.store";
import DateSelection from "@/components/mobile/elements/DateSelection.vue";

export default defineComponent({
    components: {
        DateSelection,
        FontAwesomeIcon,
        ResponsiveStageLayout
    },
    props: {
        dateSlots: {
            type: Object as PropType<Record<string, DaySlots>>,
            required: true
        },
        selectedAppointment: {
            type: String,
            required: true
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["submit", "back"],
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            appointmentState: AppointmentStore.getState()
        };
    },
    computed: {
    }
});
