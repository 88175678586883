
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";

export default defineComponent({
    props: {
        time: {
            type: String,
            required: true
        }
    },
    computed: {
        formatTime (): string {
            return dateFormat(new Date(this.time), "H:mm", { locale: dateFr });
        }
    }
});
