
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import FontAwesomeIcon from "@/utils/fontawesome";
import { DateSlot, DaySlots } from "@/services/appointment.service";

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        dateSlots: {
            type: Object as PropType<Record<string, DaySlots>>,
            required: true
        },
        selectedAppointment: {
            type: String,
            required: true
        },
        selectedDate: {
            type: String,
            required: true
        },
        selectedTime: {
            type: Number,
            required: true
        }
    },
    computed: {
        displayDate (): string {
            const date = new Date(this.selectedTime);
            return dateFormat(date, "EEEE d MMMM à H:mm", { locale: dateFr });
        }
    }
});
