
import { defineComponent, PropType } from "vue";
import { fr as dateFr } from "date-fns/locale";
import { addMinutes, format as dateFormat } from "date-fns";
import * as Yup from "yup";
import ResponsiveStageLayout from "@/components/mobile/ResponsiveStageLayout.vue";
import AppointmentStore from "@/stores/appointment.store";
import PharmacyStore from "@/stores/pharmacy.store";
import FontAwesomeIcon from "@/utils/fontawesome";
import { Appointment, DateSlot, DaySlots } from "@/services/appointment.service";
import ResponsiveInput from "@/components/mobile/elements/ResponsiveInput.vue";
import ResponsiveButton from "@/components/mobile/elements/ResponsiveButton.vue";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        ResponsiveButton,
        ResponsiveInput,
        ResponsiveStageLayout
    },
    props: {
        dateSlots: {
            type: Object as PropType<Record<string, DaySlots>>,
            required: true
        },
        selectedAppointment: {
            type: String,
            required: true
        },
        selectedDate: {
            type: String,
            required: true
        },
        selectedTime: {
            type: Number,
            required: true
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["submit", "back"],
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            appointmentState: AppointmentStore.getState(),
            mailRules: Yup.string()
                .required("Vous devez entrer un email.")
                .email("Vous devez entrer un email valide."),
            firstNameRules: Yup.string()
                .required("Vous devez entrer un prénom.")
                .min(2, "Votre prénom doit contenir au moins 2 caractères."),
            lastNameRules: Yup.string()
                .required("Vous devez entrer un nom de famille.")
                .min(2, "Votre nom de famille doit contenir au moins 2 caractères."),
            firstName: "",
            lastName: "",
            mail: "",
            phone: ""
        };
    },
    computed: {
        displayDate (): string {
            const date = new Date(this.selectedTime);
            return dateFormat(date, "EEEE d MMMM à H:mm", { locale: dateFr });
        }
    },
    methods: {
        async checkForm (): Promise<boolean> {
            let isValid = true;

            isValid = isValid && await (this.$refs.firstNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.lastNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.mailInput as typeof ResponsiveInput).checkValidity();

            return isValid;
        },
        async submit (): Promise<void> {
            const isValid = await this.checkForm();

            if (!isValid) {
                return;
            }

            const appointment: Partial<Appointment> = {
                appointmentTypeId: this.selectedAppointment,
                startDate: new Date(this.selectedTime),
                firstName: this.firstName,
                lastName: this.lastName,
                mail: this.mail,
                phone: this.phone
            };

            await AppointmentStore.register(appointment);

            this.$emit("submit");
        }
    }
});
