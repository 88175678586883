
import { defineComponent } from "vue";
import * as Yup from "yup";

export default defineComponent({
    props: {
        autofocus: Boolean,
        inputId: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        modelValue: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        rules: {
            type: Object,
            default: null
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        step: {
            type: String,
            default: undefined
        },
        prepend: {
            type: String,
            default: undefined
        },
        append: {
            type: String,
            default: undefined
        }
    },
    emits: ["blur", "submit", "update:modelValue"],
    data () {
        return {
            error: "" as (string | undefined),
            value: "",
            shouldCheckValidity: false,
            valid: false
        };
    },
    computed: {
        hasContent (): boolean {
            if (["time", "date"].includes(this.type)) {
                return true;
            }

            if (this.prepend || this.append) {
                return true;
            }

            return this.value !== "";
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler () {
                this.value = this.modelValue;
            }
        },
        value () {
            this.$emit("update:modelValue", this.value);
        }
    },
    mounted (): void {
        if (this.autofocus) {
            setTimeout(() => {
                (this.$refs.input as HTMLInputElement).focus();
            }, 200);
        }
    },
    methods: {
        async updateValidity (event?: KeyboardEvent): Promise<void> {
            if (event?.key === "Enter") {
                this.$emit("submit");
            }

            if (this.shouldCheckValidity && this.rules) {
                try {
                    await (this.rules as Yup.StringSchema).validate(this.value);
                    this.error = "";
                    this.valid = true;
                }
                catch (error) {
                    this.error = error.errors[0];
                    this.valid = false;
                }
            }
        },
        async checkValidity (): Promise<boolean> {
            this.shouldCheckValidity = true;
            await this.updateValidity();
            return this.valid;
        },
        focus (): void {
            (this.$refs.input as HTMLInputElement).focus();
        }
    }
});
