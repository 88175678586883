
import { defineComponent } from "vue";
import ResponsiveTopLogo from "@/components/mobile/elements/ResponsiveTopLogo.vue";
import Appointment from "@/components/mobile/Appointment.vue";

export default defineComponent({
    components: {
        ResponsiveTopLogo,
        Appointment
    }
});
