<template>
    <ResponsiveStageLayout :stage="stage">
        <div
            class="back"
            @click="$emit('back')"
        >
            <FontAwesomeIcon icon="angle-double-left" />
        </div>
        <h1>{{ pharmacyState.pharmacy.name }}</h1>
        <p>
            Choisissez une heure pour votre rdv<br>
            <span class="appointment-name">{{ appointmentState.appointmentTypes[selectedAppointment].name }}</span><br>
            <span class="appointment-date">{{ displayDate }}</span>
        </p>
        <div class="spacer" />
        <div class="time-wrapper">
            <TimeSelection
                v-for="slot in dateSlots[selectedDate]"
                :key="slot"
                :time="slot"
                @click="$emit('submit', slot)"
            />
        </div>
        <div class="spacer" />
    </ResponsiveStageLayout>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { fr as dateFr } from "date-fns/locale";
import { format as dateFormat } from "date-fns";
import ResponsiveStageLayout from "@/components/mobile/ResponsiveStageLayout.vue";
import AppointmentStore from "@/stores/appointment.store";
import PharmacyStore from "@/stores/pharmacy.store";
import FontAwesomeIcon from "@/utils/fontawesome";
import { DateSlot, DaySlots } from "@/services/appointment.service";
import TimeSelection from "@/components/mobile/elements/TimeSelection.vue";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        ResponsiveStageLayout,
        TimeSelection
    },
    props: {
        dateSlots: {
            type: Object as PropType<Record<string, DaySlots>>,
            required: true
        },
        selectedAppointment: {
            type: String,
            required: true
        },
        selectedDate: {
            type: String,
            required: true
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["submit", "back"],
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            appointmentState: AppointmentStore.getState()
        };
    },
    computed: {
        displayDate (): string {
            const date = new Date(this.selectedDate);
            return dateFormat(date, "EEEE d MMMM", { locale: dateFr });
        },
        daySlots (): DaySlots {
            return this.dateSlots[this.selectedDate];
        }
    }
});
</script>

<style scoped lang="scss">
.back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2vmin 4vmin;
    font-size: 8vmin;
    cursor: pointer;
    opacity: 0.2;
}

h1,
p {
    padding: 0;
    margin: 0;
    text-align: center;
}

h1 {
    font-size: 6vmin;
}

.appointment-name {
    font-size: 5vmin;
    font-weight: 600;
    color: var(--color-primary);
}

.appointment-date {
    font-weight: 600;
    text-transform: capitalize;
}

.spacer {
    height: 4vmin;
}

.time-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20vmin, 1fr));
    gap: 4vmin;
}
</style>
