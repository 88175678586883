<template>
    <div class="check">
        <FontAwesomeIcon
            icon="check"
            class="icon"
        />
        <div class="spacer" />
        <p>
            Votre rendez-vous a bien été enregistré.
        </p>
        <p class="date">
            {{ displayDate }}
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import FontAwesomeIcon from "@/utils/fontawesome";
import { DateSlot, DaySlots } from "@/services/appointment.service";

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        dateSlots: {
            type: Object as PropType<Record<string, DaySlots>>,
            required: true
        },
        selectedAppointment: {
            type: String,
            required: true
        },
        selectedDate: {
            type: String,
            required: true
        },
        selectedTime: {
            type: Number,
            required: true
        }
    },
    computed: {
        displayDate (): string {
            const date = new Date(this.selectedTime);
            return dateFormat(date, "EEEE d MMMM à H:mm", { locale: dateFr });
        }
    }
});
</script>

<style scoped lang="scss">
.check {
    text-align: center;
    color: var(--color-text-lightest);
    font-size: 5vmin;
    padding: 2vmin;

    .spacer {
        height: 4vmin;
    }

    .icon {
        font-size: 20vmin;
    }

    p {
        margin: 0;
    }

    .date {
        font-size: 6vmin;
        font-weight: 800;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
</style>
