<template>
    <ResponsiveStageLayout :stage="stage">
        <h1>{{ pharmacyState.pharmacy.name }}</h1>
        <p>Choisissez le type de rendez-vous</p>
        <div class="spacer" />
        <div class="appointment-selection">
            <ResponsiveButton
                v-for="appointmentType of appointmentTypes"
                :key="appointmentType.id"
                class="button"
                @click="$emit('submit', appointmentType.id)"
            >
                <div>
                    <div class="type-name">
                        {{ appointmentType.name }}
                    </div>
                    <div class="type-duration">
                        ({{ appointmentType.duration }} minutes)
                    </div>
                </div>
            </ResponsiveButton>
        </div>
    </ResponsiveStageLayout>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AppointmentType } from "@/services/appointment.service";
import ResponsiveButton from "@/components/mobile/elements/ResponsiveButton.vue";
import ResponsiveStageLayout from "@/components/mobile/ResponsiveStageLayout.vue";
import PharmacyStore from "@/stores/pharmacy.store";

export default defineComponent({
    components: {
        ResponsiveButton,
        ResponsiveStageLayout
    },
    props: {
        appointmentTypes: {
            type: Array as PropType<Array<AppointmentType>>,
            required: true
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["submit"],
    data () {
        return {
            pharmacyState: PharmacyStore.getState()
        };
    }
});
</script>

<style scoped lang="scss">
h1,
p {
    padding: 0;
    margin: 0;
    text-align: center;
}

h1 {
    font-size: 6vmin;
}

.spacer {
    height: 4vmin;
}

.appointment-selection {
    display: flex;
    flex-direction: column;

    .button {
        margin: 2vmin 0;
        text-align: center;

        .type-name {
            text-align: center;
            font-size: 5vmin;
        }

        .type-duration {
            font-size: 3vmin;
        }
    }
}
</style>
