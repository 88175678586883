
import { defineComponent } from "vue";
import ResponsiveStageDots from "@/components/mobile/elements/ResponsiveStageDots.vue";
import ResponsivePopup from "@/components/mobile/elements/ResponsivePopup.vue";

export default defineComponent({
    components: {
        ResponsivePopup,
        ResponsiveStageDots
    },
    props: {
        stage: {
            type: Number,
            default: 0
        }
    }
});
