
import { defineComponent, PropType } from "vue";
import { AppointmentType } from "@/services/appointment.service";
import ResponsiveButton from "@/components/mobile/elements/ResponsiveButton.vue";
import ResponsiveStageLayout from "@/components/mobile/ResponsiveStageLayout.vue";
import PharmacyStore from "@/stores/pharmacy.store";

export default defineComponent({
    components: {
        ResponsiveButton,
        ResponsiveStageLayout
    },
    props: {
        appointmentTypes: {
            type: Array as PropType<Array<AppointmentType>>,
            required: true
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["submit"],
    data () {
        return {
            pharmacyState: PharmacyStore.getState()
        };
    }
});
