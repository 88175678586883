<template>
    <div class="popup">
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
.popup {
    margin: 5vmin 0;
    width: calc(100vw - 10vmin);
    box-sizing: border-box;
    background: var(--color-background);
    padding: 8vmin;
    border-radius: 8vmin;
    box-shadow: var(--color-shadow-soft) 10px 10px 20px,
    var(--color-light-soft) -10px -10px 20px;
}
</style>
