<template>
    <div>
        <div
            class="form-input-wrapper"
            @click="focus"
        >
            <div class="form-input">
                <label
                    :for="inputId"
                    :class="{hasContent}"
                >
                    {{ label }}
                </label>
                <div
                    v-if="prepend"
                    class="prepend"
                >
                    {{ prepend }}
                </div>
                <input
                    :id="inputId"
                    ref="input"
                    v-model="value"
                    class="input"
                    :name="inputId"
                    :min="min"
                    :max="max"
                    :step="step"
                    :type="type"
                    @blur="checkValidity()"
                    @keyup="updateValidity"
                >
                <div
                    v-if="append"
                    class="append"
                >
                    {{ append }}
                </div>
            </div>
        </div>
        <transition name="error">
            <p
                v-if="error"
                class="error"
            >
                {{ error }}
            </p>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as Yup from "yup";

export default defineComponent({
    props: {
        autofocus: Boolean,
        inputId: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        modelValue: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        rules: {
            type: Object,
            default: null
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        step: {
            type: String,
            default: undefined
        },
        prepend: {
            type: String,
            default: undefined
        },
        append: {
            type: String,
            default: undefined
        }
    },
    emits: ["blur", "submit", "update:modelValue"],
    data () {
        return {
            error: "" as (string | undefined),
            value: "",
            shouldCheckValidity: false,
            valid: false
        };
    },
    computed: {
        hasContent (): boolean {
            if (["time", "date"].includes(this.type)) {
                return true;
            }

            if (this.prepend || this.append) {
                return true;
            }

            return this.value !== "";
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler () {
                this.value = this.modelValue;
            }
        },
        value () {
            this.$emit("update:modelValue", this.value);
        }
    },
    mounted (): void {
        if (this.autofocus) {
            setTimeout(() => {
                (this.$refs.input as HTMLInputElement).focus();
            }, 200);
        }
    },
    methods: {
        async updateValidity (event?: KeyboardEvent): Promise<void> {
            if (event?.key === "Enter") {
                this.$emit("submit");
            }

            if (this.shouldCheckValidity && this.rules) {
                try {
                    await (this.rules as Yup.StringSchema).validate(this.value);
                    this.error = "";
                    this.valid = true;
                }
                catch (error) {
                    this.error = error.errors[0];
                    this.valid = false;
                }
            }
        },
        async checkValidity (): Promise<boolean> {
            this.shouldCheckValidity = true;
            await this.updateValidity();
            return this.valid;
        },
        focus (): void {
            (this.$refs.input as HTMLInputElement).focus();
        }
    }
});
</script>

<style scoped lang="scss">
.form-input-wrapper {
    cursor: text;
    position: relative;
    padding: 1vmin 0;
    margin: 3vmin 0;
    font-size: inherit;

    .form-input {
        display: flex;

        label {
            position: absolute;
            top: 5px;
            left: 1px;
            color: var(--color-text-light);
            pointer-events: none;
            transition: ease-in-out 0.2s;

            &.hasContent {
                top: -12px;
                font-size: 4vmin;
            }
        }

        .prepend, .append {
            position: relative;
            top: 1px;
            opacity: 0.5;
        }

        input {
            width: 100%;
            font-size: inherit;
            background: transparent;
            border: 0;

            &:focus,
            &:active {
                outline: none;
            }
        }

        &::before {
            position: absolute;
            right: 0;
            bottom: -1px;
            left: 0;
            height: 1px;
            content: "";
            background: var(--color-shadow-soft);
        }

        &::after {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            height: 2px;
            content: "";
            background: var(--color-primary);
            transition: ease-out 0.2s;
        }

        &:focus-within {
            label {
                top: -12px;
                font-size: 4vmin;
                color: var(--color-primary);
            }

            &::after {
                width: 100%;
            }
        }
    }

    &:hover {
        .form-input::after {
            width: 100%;
        }
    }
}

.error {
    position: relative;
    top: -8px;
    max-height: 5vmin;
    margin: 0;
    font-size: 4vmin;
    line-height: 5vmin;
    color: var(--color-error);
    text-align: left;
    opacity: 1;
    transition: ease-in 0.2s;
}

.error-enter-from,
.error-leave-to {
    max-height: 0;
    font-size: 0;
    opacity: 0;
}
</style>
