
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import { DateSlot } from "@/services/appointment.service";

export default defineComponent({
    props: {
        dateSlot: {
            type: Object as PropType<DateSlot>,
            required: true
        }
    },
    computed: {
        day (): string {
            return dateFormat(new Date(this.dateSlot.date), "EEEE", { locale: dateFr });
        },
        month (): string {
            return dateFormat(new Date(this.dateSlot.date), "MMMM", { locale: dateFr });
        },
        dayNumber (): number {
            return (new Date(this.dateSlot.date)).getDate();
        }
    }
});
