<template>
    <ResponsivePopup class="popup">
        <ResponsiveStageDots
            v-if="stage"
            :stage="stage"
            class="dots"
            :total="4"
        />
        <div class="stage">
            <slot />
        </div>
    </ResponsivePopup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ResponsiveStageDots from "@/components/mobile/elements/ResponsiveStageDots.vue";
import ResponsivePopup from "@/components/mobile/elements/ResponsivePopup.vue";

export default defineComponent({
    components: {
        ResponsivePopup,
        ResponsiveStageDots
    },
    props: {
        stage: {
            type: Number,
            default: 0
        }
    }
});
</script>

<style scoped lang="scss">
.dots {
    justify-content: center;
    margin-bottom: 2vmin;
}

.popup {
    position: relative;
    z-index: 1;
    margin-top: 12vmin;
}

.stage {
    text-align: center;
}
</style>
